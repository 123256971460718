import type { RouteRecordRaw } from 'vue-router'

const AnalysisCRM: RouteRecordRaw = {
  path: '/analysis',
  component: () => import('@/layouts/analysisLayout.vue'),
  meta: {
    title: '数据分析',
    icon: 'ant-design:line-chart-outlined',
    breadcrumb: false,
  },
  children: [
    {
      // 概览
      path: 'overview',
      name: 'AnalysisOverview',
      component: () => import('@/views/crm/analysis/overview.vue'),
      meta: {
        title: '数据概览',
        icon: 'ant-design:dashboard-outlined',
        breadcrumb: true,
        sidebar: false,
        cache: false,
      },
    },
    {
      // 概览
      path: 'distributor',
      redirect: { name: 'DistributorOverview' },
      meta: {
        title: '管家分析',
        icon: 'ant-design:dashboard-outlined',
        breadcrumb: true,
        cache: false,
      },
      children: [
        {
          meta: {
            title: '管家概览',
            icon: 'ant-design:dashboard-outlined',
            breadcrumb: true,
            cache: false,
          },
          path: 'overview',
          name: 'DistributorOverview',
          component: () => import('@/views/crm/analysis/distributor/overview.vue'),
        },
        {
          meta: {
            title: '管家排行',
            icon: 'ant-design:user-outlined',
            breadcrumb: true,
            cache: true,
          },
          path: 'rank/:id',
          name: 'DistributorRank',
          component: () => import('@/views/crm/analysis/distributor/rank.vue'),
        },
        {
          meta: {
            title: '管家详情',
            icon: 'ant-design:user-outlined',
            sidebar: false,
            cache: true,
          },
          path: 'detail/:id',
          name: 'DistributorDetail',
          component: () => import('@/views/crm/analysis/distributor/detail.vue'),
        },
      ],
    },
    {
      // 概览
      path: 'customer',
      redirect: { name: 'CustomerOverview' },
      meta: {
        title: '客户分析',
        icon: 'ant-design:dashboard-outlined',
        breadcrumb: true,
        cache: false,
      },
      children: [
        {
          meta: {
            title: '客户概览',
            icon: 'ant-design:dashboard-outlined',
            breadcrumb: true,
            cache: false,
          },
          path: 'overview',
          name: 'CustomerOverview',
          component: () => import('@/views/crm/analysis/customer/overview.vue'),
        },
      ],
    },
    {
      // 概览
      path: 'product',
      redirect: { name: 'ProductOverview' },
      meta: {
        title: '商品分析',
        icon: 'ant-design:dashboard-outlined',
        breadcrumb: true,
        cache: false,
      },
      children: [
        {
          meta: {
            title: '商品概览',
            icon: 'ant-design:dashboard-outlined',
            breadcrumb: true,
            cache: false,
          },
          path: 'overview',
          name: 'ProductOverview',
          component: () => import('@/views/crm/analysis/product/overview.vue'),
        },
        {
          meta: {
            title: '商品排行',
            icon: 'ant-design:user-outlined',
            breadcrumb: true,
            cache: false,
          },
          path: 'rank/:id',
          name: 'ProductRank',
          component: () => import('@/views/crm/analysis/product/rank.vue'),
        },
        {
          meta: {
            title: '商品详情',
            icon: 'ant-design:user-outlined',
            sidebar: false,
            cache: true,
          },
          path: 'detail/:id',
          name: 'ProductDetail',
          component: () => import('@/views/crm/analysis/product/detail.vue'),
        },
      ],
    },
  ],
}

export default AnalysisCRM
